.notifications {
    min-height: 90vh;
    padding: 5% 2%;
}
.notifications h1 {
    text-align: center;
}
.notifications .notifs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2%;
}
.notifications .notif {
    display: flex;
    width: 45%;
    margin: 10px 0;
    background-color: var(--white);
}
.notifications .notif:hover {
    cursor: pointer;
}
.notifications .notif .left {
    width: 5%;
    background-color: var(--warning);
}
.notifications .notif .left.problem {
    background-color: var(--red);
}
.notifications .notif .body {
    flex: 1;
    padding: 2% 5%;
}
.notifications .notif .foot {
    text-align: right;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.notifications .notif button {
    margin: 10px 10px;
    padding: 1% 3%;
    background-color: var(--green);
    border: none;
    border-radius: 4px;
    outline: none;
}
.notifications .notif button.solve {
    background-color: var(--warning);
}