
.balances .send-amount .input-group {
    min-width: 500px;
    margin-top: 20px;
}
.balances .send-amount button {
    display: block;
    background-color: var(--red);
    margin-top: 20px;
    padding: 2%;
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-size: 0.9em;
    font-weight: bolder;
}