.sav .balances {
    display: flex;
    /* flex-wrap: wrap; */
    /* border: 1px solid rgb(243, 11, 11); */
}

.sav .claims {
    margin: 2%;
    padding: 2%;
    background-color: var(--white);
}

.sav .handle-claim, .sav .handle-refund {
    padding: 2%;
    font-size: 1.3em;
}

.sav .handle-claim div, .sav .handle-refund div {
    min-width: 500px;
    margin-top: 20px;
}
.sav .refund-min:hover {
    background-color: var(--red-transparent);
    cursor: pointer;
}

.sav .claims button.details {
    background-color: var(--red);
    padding: 5%;
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-weight: bolder;
}

.sav .claims .handle-claim .claim-actions {
    display: flex;
    justify-content: flex-end;
}

.sav .claims .handle-claim .modal-button {
    display: block;
    background-color: var(--red);
    margin: 0 2%;
    padding: 2%;
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-size: 0.9em;
    font-weight: bolder;
}

.sav .refunds {
    margin: 2%;
    padding: 2%;
    background-color: var(--white);
}